<template>
  <div class="Rule-container">
    <!-- 导航栏 -->
    <van-nav-bar
      title="Rule"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'"
    >
      <i
        slot="left"
        class="iconfont icon-jiantou1"
        @click="$router.back()"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"
      ></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'">Rule</span>
      </template>
    </van-nav-bar>
  </div>
</template>
<script>
import { yuebaoRule } from '@/api/yuebao.js'
export default {
  name: 'Rule',
  components: {},
  data() {
    return {}
  },
  created() {
    // TODO:
    // this.getRule()
  },
  mounted() {},
  methods: {
    async getRule() {
      const res = await yuebaoRule()
      
    },
  },
}
</script>
<style lang="less" scoped>
.Rule-container {
  /deep/ .van-nav-bar__content {
    // background-color: #fc6925;
    /deep/ .van-nav-bar__title {
      // color: #fff;
    }
    .iconfont {
      font-size: 44px;
      // color: #fff;
    }
  }
}
</style>
